import { Icon, IconButton, List, ListItem, ListItemText, Popover, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import React, { Fragment, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { ReceiptWithCubeTest } from '../../containers/Sampling'
import { ConsistencyClass, ConsistencyTest, ConsistencyTestType, CubeTest } from '../../types'
import moment from "moment";

const order = ['C0', 'C1', 'S2', 'S3', 'S4', 'F4', 'F5', 'SF1', 'SF2', 'SF3']

const GroupRow: React.FC<{ showDeviations: boolean, group: { consistencyClass: ConsistencyClass, consistencyTestType: ConsistencyTestType, tests: Array<{ cubeTest: CubeTest, consistencyTest: ConsistencyTest }> } }> = ({ showDeviations, group: { consistencyClass, consistencyTestType, tests } }) => {
  const [anchorElDeviations, setAnchorElDeviations] = React.useState<HTMLButtonElement | null>(null)
  const [anchorElOutsideMax, setAnchorElOutsideMax] = React.useState<HTMLButtonElement | null>(null)
  const [anchorElTests, setAnchorElTests] = React.useState<HTMLButtonElement | null>(null)
  const history = useHistory()

  const outsideMax = useMemo(() => {
    return tests.filter(({ consistencyTest }) => !consistencyTest.values[0].value || (consistencyClass.minDeviation && consistencyTest.values[0].value < consistencyClass.minDeviation) || (consistencyClass.maxDeviation && consistencyTest.values[0].value > consistencyClass.maxDeviation))
  }, [tests, consistencyClass])

  const deviations = useMemo(() => {
    return tests.filter(({ consistencyTest }) => !consistencyTest.values[0].value || (consistencyClass.minRequirement && consistencyTest.values[0].value < consistencyClass.minRequirement) || (consistencyClass.maxRequirement && consistencyTest.values[0].value > consistencyClass.maxRequirement))
  }, [tests, consistencyClass])

  return <Fragment>
    <TableRow>
      <TableCell rowSpan={showDeviations ? 2 : 1}>{consistencyClass.code}</TableCell>
      <TableCell>{consistencyTestType.description}</TableCell>
      <TableCell>
        {tests.length}
        <IconButton className="hide-print" onClick={e => setAnchorElTests(e.currentTarget)} style={{ margin: '-14px 0' }}><Icon>info</Icon></IconButton>
        <Popover
          id={consistencyClass.id + 'tests'}
          open={Boolean(anchorElTests)}
          anchorEl={anchorElTests}
          onClose={() => setAnchorElTests(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <List dense={true}>
            {tests.map((d, k) => <ListItem key={k} button={true} onClick={() => history.push(`/sample/${d.cubeTest.cubeNumber}`)}>
              <ListItemText primary={`Kubus: ${d.cubeTest.cubeNumber}`} secondary={`Meeting: ${d.consistencyTest.values[0].value} ${consistencyTestType.parameters[0].unit}`} />
            </ListItem>)}
          </List>
        </Popover>
      </TableCell>
      <TableCell>
        {deviations.length} <Typography variant="caption">({!consistencyClass.maxDeviation && '≥ '}{consistencyClass.minRequirement}{consistencyClass.maxRequirement && ` - ${consistencyClass.maxRequirement}`}{consistencyTestType.parameters[0].unit && ` ${consistencyTestType.parameters[0].unit}`})</Typography>
        {!showDeviations && deviations.length > 0 && <IconButton className="hide-print" onClick={e => setAnchorElDeviations(e.currentTarget)} style={{ margin: '-14px 0' }}><Icon>info</Icon></IconButton>}
        <Popover
          id={consistencyClass.id + 'deviation'}
          open={Boolean(anchorElDeviations)}
          anchorEl={anchorElDeviations}
          onClose={() => setAnchorElDeviations(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <List dense={true}>
            {deviations.map((d, k) => <ListItem key={k} button={true} onClick={() => history.push(`/sample/${d.cubeTest.cubeNumber}`)}>
              <ListItemText primary={`Kubus: ${d.cubeTest.cubeNumber}`} secondary={`Meeting: ${d.consistencyTest.values[0].value} ${consistencyTestType.parameters[0].unit}`} />
            </ListItem>)}
          </List>
        </Popover>
      </TableCell>
      <TableCell>{consistencyClass.maxDeviationsAllowed}</TableCell>
      <TableCell>
        {outsideMax.length} <Typography variant="caption">({!consistencyClass.maxDeviation && '≥ '}{consistencyClass.minDeviation}{consistencyClass.maxDeviation && ` - ${consistencyClass.maxDeviation}`}{consistencyTestType.parameters[0].unit && ` ${consistencyTestType.parameters[0].unit}`})</Typography>
        {!showDeviations && outsideMax.length > 0 && <IconButton className="hide-print" onClick={e => setAnchorElOutsideMax(e.currentTarget)} style={{ margin: '-14px 0' }}><Icon>info</Icon></IconButton>}
        <Popover
          id={consistencyClass.id + 'outsidemax'}
          open={Boolean(anchorElOutsideMax)}
          anchorEl={anchorElOutsideMax}
          onClose={() => setAnchorElOutsideMax(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <List dense={true}>
            {outsideMax.map((d, k) => <ListItem key={k} button={true} onClick={() => history.push(`/sample/${d.cubeTest.cubeNumber}`)}>
              <ListItemText primary={`Kubus: ${d.cubeTest.cubeNumber}`} secondary={`Meeting: ${d.consistencyTest.values[0].value} ${consistencyTestType.parameters[0].unit}`} />
            </ListItem>)}
          </List>
        </Popover>
      </TableCell>
      <TableCell>{outsideMax.length === 0 && (!consistencyClass.maxDeviationsAllowed || deviations.length <= consistencyClass.maxDeviationsAllowed) ? 'Ja' : 'Nee'}</TableCell>
    </TableRow>
    {showDeviations && <TableRow>
      <TableCell colSpan={6} size="small">
        <Typography variant="caption">Afwijkingen: {deviations.map(d => `${d.cubeTest.cubeNumber} (${d.consistencyTest.values[0].value}${consistencyTestType.parameters[0].unit && ` ${consistencyTestType.parameters[0].unit}`})`).join(', ')}</Typography>
      </TableCell>
    </TableRow>}
  </Fragment>
}

const ReportConsistency: React.FC<{ receipts: ReceiptWithCubeTest[], startDate: Date, showDeviations: boolean }> = ({ receipts, startDate, showDeviations }) => {
  // Filter receipts
//  receipts=receipts.filter(r => moment(r.cubeTest.sampleDate).isSameOrAfter(startDate));

  const grouped = useMemo(() => receipts.reduce((groups, receipt) => {
    receipt.cubeTest.consistencyTests.filter(({ consistencyTestType }) =>
      receipt.revision.recipe.consistencyClass.consistencyTestTypes.map(t => t.id).indexOf(consistencyTestType.id) >= 0).forEach(consistencyTest => {
      const index = groups.findIndex(group => group.consistencyClass.id === receipt.revision.recipe.consistencyClass.id)
      if (index >= 0) {
        groups[index].tests.push({ cubeTest: receipt.cubeTest, consistencyTest })
      } else {
        groups.push({ consistencyClass: receipt.revision.recipe.consistencyClass, consistencyTestType: consistencyTest.consistencyTestType, tests: [{ cubeTest: receipt.cubeTest, consistencyTest }] })
      }
    })
    return groups
  }, [] as Array<{ consistencyClass: ConsistencyClass, consistencyTestType: ConsistencyTestType, tests: Array<{ cubeTest: CubeTest, consistencyTest: ConsistencyTest }> }>)
    .sort((a, b) => order.indexOf(a.consistencyClass.code) - order.indexOf(b.consistencyClass.code)), [receipts])

  return <Fragment>
    <Typography variant="h5" style={{ paddingLeft: 16 }}>Consistentiemetingen</Typography>
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Klasse</TableCell>
          <TableCell>Methode</TableCell>
          <TableCell>Waarnemingen</TableCell>
          <TableCell>Afwijkingen</TableCell>
          <TableCell>Toegestaan</TableCell>
          <TableCell>Buiten max</TableCell>
          <TableCell>Voldoet</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {grouped.map(group => <GroupRow key={group.consistencyClass.id} group={group} showDeviations={showDeviations} />)}
      </TableBody>
    </Table>
  </Fragment>
}

export default ReportConsistency
