import { CircularProgress, Collapse, Divider, Grid, Icon, IconButton, LinearProgress, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, ListSubheader, makeStyles } from '@material-ui/core'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { AppContext } from '../App'
import { ConfirmDialog, Loading } from '../components'
import AddSampleDialog from '../components/Sampling/AddSampleDialog'
import CubeTestListItem from '../components/Sampling/CubeTestListItem'
import CubeTestsList from '../components/Sampling/CubeTestsList'
import SamplesTodayDialog from '../components/Sampling/SamplesTodayDialog'
import {
  ConsistencyTestInput,
  ConsistencyTestType,
  CubeInput,
  CubeStatus,
  CubeTest,
  CubeTestInput,
  CubeType,
  Receipt
} from '../types'
import useAuthorized from '../useAuthorized'
import moment from 'moment'
import CubeTestEdit from '../components/Sampling/CubeTestEdit'
import EmptyState from '../components/EmptyState'
import { CubeIcon } from '../icons'
import { sanitizeReceipt } from '../sanitize'
import { v4 as uuid } from 'uuid'
import {getWithCubeNumber, remove} from "../HTTPClients/RecipeApp/receipts";
import {getAll, insert, update} from "../HTTPClients/RecipeApp/receipts";

const useCollapseStyles = makeStyles({
  entered: {
    flex: 1
  },
  wrapper: {
    height: '100%'
  },
  wrapperInner: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto'
  }
})

const useSamplingStyles = makeStyles({
  loadingProgress: {
    background: 'none',
    position: 'fixed',
    top: 64,
    left: 0,
    width: 339
  },
  progressWrapper: {
    position: 'relative',
  },
  iconProgress: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 1,
  }
})

export type ReceiptWithCubeTest = Receipt & { cubeTest: CubeTest }

type SamplesByDay = Array<{ date: Date, receipts: ReceiptWithCubeTest[] }>

type SamplingProps = {

}

let hasFetchedMore = false
let persistTimeout: any

const Sampling: React.FC<SamplingProps> = (props) => {
  const [showSamplesToday, setShowSamplesToday] = useState(false)
  const [showPrev, setShowPrev] = useState(false)
  const [selected, setSelected] = useState(undefined as ReceiptWithCubeTest | undefined)
  const [addSampleDialogProps, addSampleDialog] = useState({ open: false } as { open: boolean, onAdd?: (receipt: Receipt) => void })
  const [confirmDeleteDialogProps, confirmDelete] = useState({ open: false } as { open: boolean, onCancel?: () => void, onConfirm?: () => void })
  const { selectedPlant, setIsSaving, setIsSaved } = useContext(AppContext)
  const [adding, setAdding] = useState(false)
  const canAdd = useAuthorized(['create:cubetests'])
  const { loadingProgress, progressWrapper, iconProgress } = useSamplingStyles()
  const collapseClasses = useCollapseStyles()
  const history = useHistory()
  const { cubeNumber } = useParams<{ cubeNumber?: string }>()
  const [loading, setLoading]=useState(true);
  const [receipts, setReceipts]=useState([] as Receipt[]);
  const samplesByDay = useMemo(() =>
      receipts.length ? groupReceiptsByDay(receipts.filter(
          r => r.cubeTest && r.cubeTest.cubeNumber) as ReceiptWithCubeTest[]).sort(
              (a, b) =>
                  moment(b.date).diff(a.date)) : [] as SamplesByDay,[receipts])
  const samplesToday = useMemo(() =>
      samplesByDay.find(({ date }) => moment(date).isSame(new Date(),'day'))?.receipts,[samplesByDay])
  const lastCubeNumber = useMemo(() =>
      receipts.length ? receipts.map(({ cubeTest }) =>
          cubeTest?.cubeNumber || 0).sort((a, b) => a - b).pop() : 0, [receipts])

  useEffect(() => {
    if(cubeNumber) {
      // @ts-ignore
      getWithCubeNumber(selectedPlant.id, Number(cubeNumber)).then(
          function(response: any) {
            setSelected(response.data.data)
          }
      )
    }
  }, [cubeNumber, selectedPlant, setSelected])

  useEffect(() => {
    if(selectedPlant!==undefined) {
      let dateFormat='YYYY-MM-DDTHH:mm:ssZ';
//      let startDate=moment().subtract(1,'month').startOf('month').format(dateFormat);
      let startDate=moment().startOf('day').format(dateFormat);
      let endDate=moment().subtract(0,'day').endOf('day').format(dateFormat);
      // @ts-ignore
      getAll(selectedPlant.id,'', startDate, endDate).then(
          function(response: any) {
            setReceipts(response.data.data);
            setLoading(false);
          }
      )
    }
  },[selectedPlant, setReceipts])

  useEffect(() => {
    if(showPrev && !hasFetchedMore) {
      let dateFormat='YYYY-MM-DDTHH:mm:ssZ';
      let startDate=moment().subtract(1,'month').startOf('month').format(dateFormat);
      let endDate=moment().subtract(0,'day').endOf('day').format(dateFormat);
      // @ts-ignore
      getAll(selectedPlant.id,'', startDate, endDate).then(
          function(response: any) {
            setReceipts(response.data.data);
            hasFetchedMore=true;
          }
      )
    }
  },[showPrev, selectedPlant, hasFetchedMore])

  const handleSelect = useCallback((receipt: ReceiptWithCubeTest) => {
    history.push('/sample/' + receipt.cubeTest?.cubeNumber)
  }, [history])

  const handleOpenAddDialog = useCallback(() => {
    addSampleDialog({
      open: true,
      onAdd: async (receipt: Receipt, createReceipt: boolean = false) => {
        addSampleDialog({ open: false })
        setAdding(true)
        const cubeTest = newCubeTest(receipt)
        let data=sanitizeReceipt(receipt) as any;
        data['cubeTest']=cubeTest;
        data['revision']=receipt.revision.id;
        // @ts-ignore
        data['plant']=selectedPlant.id;
        data['id']=receipt.id;

        if (createReceipt) {
          if(typeof data['customer']==='string')
            data['customer']=data['customer'].trim()
          if(typeof data['project']==='string')
            data['project']=data['project'].trim().toUpperCase();

          try {
            let response = await insert(data);
            let newReceipt=response.data.data;

            // @ts-ignore
            getAll(selectedPlant.id).then(
              function(response: any) {
                setReceipts(response.data.data);
              }
            )

            // @ts-ignore
            history.push(`/sample/${newReceipt.cubeTest.cubeNumber}`)

          } catch(error) {
            console.log(error);
            if(error.response.data==='not_unique') {
              await update(data);
            }
          }
        } else {
          await update(data);
        }

        updateList()
        setAdding(false)
      }
    })
  }, [history, selectedPlant])

  const handleChange = useCallback(async (receipt: ReceiptWithCubeTest) => {
    persistTimeout && clearTimeout(persistTimeout)
    persistTimeout = setTimeout(async () => {
      setIsSaving(true)

      let data=sanitizeReceipt(receipt) as any;
      data['id']=receipt.id;
      // @ts-ignore
      data['plant']=selectedPlant.id;
      await update(data);

      updateList()

      setIsSaving(false)
      setIsSaved(true)
      setTimeout(() => setIsSaved(false), 5000)
    }, 1000)
  }, [setIsSaving, selectedPlant, setIsSaved, setReceipts])

  const handleDelete = useCallback(async (receipt: ReceiptWithCubeTest) => {
    confirmDelete({
      open: true,
      onCancel: () => confirmDelete({ open: false }),
      onConfirm: async () => {
        confirmDelete({ open: false })
        if (selected && selected.cubeTest.cubeNumber === receipt.cubeTest.cubeNumber) {
          history.push('/sample')
        }
        // @ts-ignore
        await remove(selectedPlant.id, receipt.id);
        setSelected(undefined);

        // @ts-ignore
        getAll(selectedPlant.id).then(
          function(response: any) {
            setReceipts(response.data.data);
          }
        )
      }
    })
  }, [selected, selectedPlant, history])

  function updateList():void {
    // @ts-ignore
    let dateFormat='YYYY-MM-DDTHH:mm:ssZ';
//    let startDate=moment().subtract(1,'month').startOf('month').format(dateFormat);
    let startDate=moment().startOf('day').format(dateFormat);
    let endDate=moment().subtract(0,'day').endOf('day').format(dateFormat);
    // @ts-ignore
    getAll(selectedPlant.id,'', startDate, endDate).then(
      function(response: any) {
        setReceipts(response.data.data);
      }
    )
  }

  return <Grid container={true} style={{ flex: 1, overflow: 'hidden' }}>
    <Grid item={true} style={{ display: 'flex', flexDirection: 'column', height: '100%', borderRight: '1px solid #444' }}>
      {loading && <LinearProgress variant="indeterminate" className={loadingProgress} />}
      <Collapse in={!showPrev} classes={collapseClasses}>
        {canAdd && <ListItem button={true} disabled={adding} dense={true} onClick={handleOpenAddDialog}>
          <ListItemIcon>
            <div className={progressWrapper}>
              <Icon color={adding ? 'disabled' : 'secondary'}>add_circle</Icon>
              {adding && <CircularProgress size={24} className={iconProgress} color="secondary" />}
            </div>
          </ListItemIcon>
          <ListItemText primary="Monstername toevoegen" />
        </ListItem>}
        <CubeTestsList>
          {samplesToday && samplesToday.sort((a, b) => (a.cubeTest?.cubeNumber || 0) - (b.cubeTest?.cubeNumber || 0)).map((receipt, x) => (
            <CubeTestListItem
              key={receipt.cubeTest?.cubeNumber}
              receipt={receipt}
              selected={selected && selected.id === receipt.id}
              onSelect={() => handleSelect(receipt)}
              onDelete={() => handleDelete(receipt)}
              isLast={lastCubeNumber === receipt.cubeTest?.cubeNumber}
            />
          ))}
        </CubeTestsList>
        {!samplesToday && <List dense={true}><ListItemText primary="Geen monsternames" /></List>}
      </Collapse>
      <List disablePadding={true} style={showPrev ? { maxHeight: '100%', display: 'flex', flexDirection: 'column' } : {}}>
        <Divider />
        <ListItem button={true} onClick={() => {
            history.push('/sample'); setSelected(undefined); setShowPrev(!showPrev)}
          }>
          <ListItemText primaryTypographyProps={{ variant: 'subtitle1' }} primary="Overige monsternames" />
          <ListItemSecondaryAction>
            <IconButton onClick={() => {
              history.push('/sample');
              setSelected(undefined);
              setShowPrev(!showPrev)}
            }><Icon>{showPrev ? 'close' : 'expand_less'}</Icon></IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
      <Collapse in={showPrev} classes={collapseClasses}>
        {samplesByDay.filter(({ date }) => !moment(date).isSame(new Date(), 'day')).map(({ date, receipts }, x) => <List key={x} disablePadding={true}>
          <ListSubheader style={{ background: '#303030' }}>{moment(date).format('dddd, D MMM YYYY')}</ListSubheader>
          {receipts.sort((a, b) => (b.cubeTest?.cubeNumber || 0) - (a.cubeTest?.cubeNumber || 0)).map((receipt) => (
            <CubeTestListItem
              key={receipt.cubeTest?.cubeNumber}
              receipt={receipt}
              selected={selected && selected.id === receipt.id}
              onSelect={() => handleSelect(receipt)}
              onDelete={() => handleDelete(receipt)}
            />
          ))}
        </List>)}
      </Collapse>
    </Grid>
    {selected ? <CubeTestEdit receipt={selected} onChange={handleChange} /> :
      loading ? <Loading /> : <EmptyState Icon={CubeIcon} text="Selecteer een monstername" />
    }
    <SamplesTodayDialog
      receipts={receipts}
      open={showSamplesToday}
      onClose={() => setShowSamplesToday(false)}
    />
    <ConfirmDialog
      title="Monstername verwijderen"
      content="Weet u zeker dat u deze monstername wilt verwijderen?"
      confirmText="Verwijderen"
      {...confirmDeleteDialogProps}
    />
    <AddSampleDialog
      {...addSampleDialogProps}
      receipts={receipts.filter(r => typeof r.cubeTest === 'undefined') || []}
      onClose={() => addSampleDialog({ open: false })}
    />
  </Grid>
}

export default Sampling

function groupReceiptsByDay(receipts: Array<Receipt & { cubeTest: CubeTest }>) {
  return receipts.reduce((groups, receipt) => {
    const index = groups.findIndex(group => moment(group.date).isSame(receipt.cubeTest?.sampleDate, 'day'))
    if (index >= 0) {
      groups[index].receipts.push(receipt)
    } else {
      groups.push({ date: receipt.cubeTest?.sampleDate, receipts: [receipt] })
    }
    return groups
  }, [] as SamplesByDay)
}

function newCubeTest(receipt: Receipt): CubeTestInput {
  return {
    sampleDate: receipt.date,
    consistencyTests: receipt.revision.recipe.consistencyClass.consistencyTestTypes.map(c => newConsistencyTest(c)),
    cubes: [newCube(receipt)]
  }
}

function newConsistencyTest(consistencyTestType: ConsistencyTestType): ConsistencyTestInput {
  return {
    consistencyTestType,
    values: consistencyTestType.parameters.map(parameter => ({ parameter }))
  }
}

function newCube(receipt: Receipt): CubeInput {
  return {
    hash: uuid(),
    numberOfDays: 28,
    testDate: moment(receipt.date).add(28, 'days').toDate(),
    type: CubeType.Pressure,
    status: CubeStatus.Active,
    weight: 0
  }
}


