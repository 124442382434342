import React, {Fragment, useMemo} from 'react'
import {Cube, CubeTest, ResourceType} from '../../types'
import { FamilyWithReceipts } from './EN206'
import {EN206CubeChunks} from "./EN206CubeChunks";
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography, useTheme } from '@material-ui/core'
import NumberFormat from 'react-number-format'
import moment from 'moment'
import {Link} from 'react-router-dom'

const numberFormatProps = {
  thousandSeparator: '.',
  decimalSeparator: ',',
  displayType: 'text' as 'text'
}

function sortCubeTests(a: CubeTest, b: CubeTest) {
  const aDate = a.sampleDate ? new Date(a.sampleDate) : 0
  const bDate = b.sampleDate ? new Date(b.sampleDate) : 0
  return (aDate > bDate && 1) || (aDate < bDate && -1) || 0
}

const ReportPressureStrength: React.FC<{ family: FamilyWithReceipts, startDate: Date, endDate: Date }> = ({ family, startDate, endDate }) => {
  const sorted = useMemo(() =>
    family.receipts.map(receipt => receipt.cubeTest).sort(sortCubeTests),[family.receipts])

  let characteristicStrength = 0
    //this SHOULD always be available but handling checks anyway
  if (family.receipts.length > 0 &&
    family.receipts[0].revision &&
    family.receipts[0].revision.recipe &&
    family.receipts[0].revision.recipe.strengthClass){
    characteristicStrength = family.receipts[0].revision.recipe.strengthClass.cubePressureStrength
  }

  let cubes = useMemo(() =>
    sorted.reduce((arr, cubeTest) =>
      [...arr, ...cubeTest.cubes.filter(cube =>
        cube.numberOfDays && cube.numberOfDays === 28 && cube.pressureStrength && cube.pressureStrength > 0).map(cube =>
           ({ ...cube, cubeNumber: cubeTest.cubeNumber }))],
      [] as Array<Cube & { cubeNumber: number }>),[sorted])


  let displayChunks=EN206CubeChunks(cubes, startDate, endDate, characteristicStrength)

  const maxLen = 15
  const { strengthClass, ingredients } = useMemo(() => family.receipts[0].revision.recipe, [family])
  const binders = useMemo(() => ingredients.filter(({ resource }) => resource.type === ResourceType.Cement || resource.type === ResourceType.Filler).map(({ resource }) => resource).sort((a, b) => a.id - b.id), [ingredients])
  const theme = useTheme()

  if(!displayChunks.length)
    return (
      <Fragment></Fragment>
    )

  return (
    <Table style={{ width: 'auto', marginBottom: 32 }} size="small">
      <TableHead>
        <TableRow>
          <TableCell colSpan={maxLen + 2} style={{ border: 0 }}>
            <Box display="flex">
              <Box>
                <Typography variant="body2">Sterkteklasse</Typography>
                <Typography>{strengthClass?.code}</Typography>
              </Box>
              <Box marginLeft={2}>
                <Typography variant="body2">Bindmiddelen</Typography>
                <Typography>{binders.map(b => b.name).join(', ')}</Typography>
              </Box>
              <Box marginLeft={2}>
                <Typography variant="body2">Familie</Typography>
                <Typography>{family.name}</Typography>
              </Box>
            </Box>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {displayChunks.map((chunk, i) => {
          return <Fragment key={i}>
            <TableRow>
              <TableCell style={{ border: 0, backgroundColor: chunk.properties.isCubes35 ? '#666' : '000'}}>{moment(chunk.properties.first.testDate).format('D-M-YYYY')}</TableCell>
              {chunk.cubes.map(cube => <TableCell key={new Date().getTime()+'-'+Math.random()} align="center" style={{ border: 0, backgroundColor: chunk.properties.isCubes35 ? '#666' : '000'}}>
                <Typography variant="caption">{moment(cube.testDate).format('D-M')}</Typography>
                <Typography variant="h6" style={{ border: 0, backgroundColor: chunk.properties.targetStrength > (cube.pressureStrength || 100) ? 'red' : '000'}}>{cube.pressureStrength}</Typography>
                <Typography variant="body2"><Link to={`/sample/${cube.cubeNumber}`} style={{ color: theme.palette.secondary.light }}>{cube.cubeNumber}</Link></Typography>
              </TableCell>)}
              <TableCell style={{ border: 0, backgroundColor: chunk.properties.isCubes35 ? '#666' : '000' }}>{moment(chunk.properties.last.testDate).format('D-M-YYYY')}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell style={{ border: 0 }} />
              <TableCell colSpan={maxLen + 1} style={{ border: 0 }}>
                <Box display="flex" >
                  <span>min: {chunk.properties.min}</span>
                  <span style={{ marginLeft: 16 }}>max: {chunk.properties.max}</span>
                  {chunk.cubes.length === 15 && <span style={{ marginLeft: 16 }}>x15: <NumberFormat value={Math.round(chunk.properties.mean * 10) / 10} {...numberFormatProps} decimalScale={1} /></span>}
                  {chunk.cubes.length === 15 && chunk.properties.fcm && <span style={{ marginLeft: 16 }}>f'cm: <NumberFormat value={Math.round(chunk.properties.fcm * 10) / 10} {...numberFormatProps} decimalScale={1} /></span>}
                  {chunk.cubes.length === 15 && <span style={{ marginLeft: 16, backgroundColor: ((chunk.properties.stdDev < chunk.properties.stdDevMin || chunk.properties.stdDev > chunk.properties.stdDevMax) && i > 2)? 'orange' : '000' }}>s15: <NumberFormat value={Math.round(chunk.properties.stdDev * 10) / 10} {...numberFormatProps} decimalScale={1} /></span>}
                  {chunk.cubes.length === 15 && chunk.properties.stdDev35 > 0 && <span style={{ marginLeft: 16 }}>s35: <NumberFormat value={Math.round(chunk.properties.stdDev35 * 10) / 10} {...numberFormatProps} decimalScale={1} /></span>}
                  {chunk.cubes.length === 15 && chunk.properties.stdDevMin > 0 && chunk.properties.stdDevMax > 0 && <span style={{ marginLeft: 16 }}><NumberFormat value={Math.round(chunk.properties.stdDevMin * 10) / 10} {...numberFormatProps} decimalScale={1} /> ... <NumberFormat value={Math.round(chunk.properties.stdDevMax * 10) / 10} {...numberFormatProps} decimalScale={1} /></span>}
                </Box>
              </TableCell>
            </TableRow>
          </Fragment>
        })}
      </TableBody>
    </Table>)
}

export default ReportPressureStrength
