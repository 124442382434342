import {Cube, CubesChunckProperties} from "../../types";
import * as math from "mathjs";

export function EN206CubeChunks(input: Array<Cube & { cubeNumber: number }>, startDate: Date, endDate: Date,
    characteristicStrength: number ): Array<{ properties : CubesChunckProperties, cubes: Array<Cube & { cubeNumber: number }>}> {
  let cubes35=input.slice(0, 35)
  let stdDev35=cubes35.length===35 ? math.std(cubes35.map(cube => cube.pressureStrength || 0)) : 0
  let last35Date = new Date(cubes35[cubes35.length - 1].testDate)
  let displayChunks: Array<{ properties : CubesChunckProperties, cubes: Array<Cube & { cubeNumber: number }>}> = [];

  let chunks: Array<{ properties : CubesChunckProperties, cubes: Array<Cube & { cubeNumber: number }>}> = [];

  if (input.length < 35){
    if (input.length <= 15){
      chunks.push({
        properties : getChunckProperties(input, stdDev35, true, characteristicStrength),
        cubes : input
      })
    } else if (input.length <= 30){
      chunks.push({
        properties : getChunckProperties(input.slice(0,15), stdDev35, true, characteristicStrength),
        cubes : input.slice(0,15)
      })
      chunks.push({
        properties : getChunckProperties(input.slice(15, input.length), stdDev35, true, characteristicStrength),
        cubes : input.slice(15, input.length)
      })
    } else {
      chunks.push({
        properties : getChunckProperties(input.slice(0,15), stdDev35, true, characteristicStrength),
        cubes : input.slice(0,15)
      })
      chunks.push({
        properties : getChunckProperties(input.slice(15,30), stdDev35, true, characteristicStrength),
        cubes : input.slice(15,30)
      })
      chunks.push({
        properties : getChunckProperties(input.slice(30, input.length), stdDev35, true, characteristicStrength),
        cubes : input.slice(30, input.length)
      })
    }
  } else {
    chunks.push({
      properties : getChunckProperties(input.slice(0,15), stdDev35, true, characteristicStrength),
      cubes : input.slice(0,15)
    })
    chunks.push({
      properties : getChunckProperties(input.slice(15,30), stdDev35, true, characteristicStrength),
      cubes : input.slice(15,30)
    })
    chunks.push({
      properties : getChunckProperties(input.slice(30, 35), stdDev35, true, characteristicStrength),
      cubes : input.slice(30, 35)
    })
  }

  //for easy of access
  let mostRecentDev35 = stdDev35
  for (let i = 35; i < input.length; i+=15){
    //grab the next 15
    let nextChunk = input.slice(i, i + 15)
    let next = {
      properties : getChunckProperties(nextChunk, mostRecentDev35,false, characteristicStrength),
      cubes : nextChunk
    }


    let checkDate = new Date(last35Date)
    checkDate.setMonth(checkDate.getMonth() + 6)
    //check the min/max of the 15
    //or if the last s35 calculation was more than 6 months ago
    if (next.cubes.length === 15 &&
      ((next.properties.stdDev > 0 &&
          (next.properties.stdDev < next.properties.stdDevMin ||
            next.properties.stdDev > next.properties.stdDevMax)) ||
        new Date(next.cubes[next.cubes.length -1].testDate) > checkDate)){

      //mark these 15 as part of 35
      next.properties.isCubes35 = true

      //mark previous chunk of 15 as part of 35
      chunks[chunks.length - 1].properties.isCubes35 = true

      //check if previous chunk had 15 or 5 cubes (there shouldn't be any other options)
      if (chunks[chunks.length - 1].cubes.length === 15){
        //if the second to last only has 5 cubes we don't need to do anything
        //if the last chunk had 15 cubes and the second to last as well we should grab 5 from the second to last and insert those as a new chunch between the 2
        if (chunks[chunks.length - 2].cubes.length === 15){
          let grab5 = chunks[chunks.length - 2].cubes.splice(10,15)
          //should insert this between last and second to last
          chunks.splice(chunks.length -1, 0,{
            properties : getChunckProperties(grab5, mostRecentDev35, true, characteristicStrength),
            cubes : grab5
          })
        }

      } else { //if not 15 it should be 5
        //if the last chunk had 5 cubes the second to last should have 15 which makes this easy
        chunks[chunks.length - 2].properties.isCubes35 = true
      }

      //recalculate the stdDev using THESE 15 and the previous 20 cubes (35 total)
      //at the end so the reason for the new calculation of 35 is traceable
      let newRange = input.slice(i-20, i+15)
      mostRecentDev35 = math.std(newRange.map(cube => cube.pressureStrength || 0))
      last35Date = new Date(newRange[newRange.length -1].testDate)
    }
    chunks.push(next)
  }

  //only show the chunks within the selected date range
  for (const chunk of chunks){
    if (new Date(chunk.properties.last.testDate) > startDate && new Date(chunk.properties.first.testDate) < endDate){
      displayChunks.push(chunk)
    }
  }
  return displayChunks
}

function getChunckProperties(chunk: any[], stdDev35: number, isCubes35: boolean=false, characteristicStrength: number): CubesChunckProperties {
  let values=chunk.map(cube => cube.pressureStrength || 0);
  let mean=math.mean(values);
  let stdDev=math.std(values);

  return {
    isCubes35: isCubes35,
    stdDev35: stdDev35,
    first: chunk[0],
    last: chunk[chunk.length - 1],
    values: values,
    mean: mean,
    min: math.min(values),
    max: math.max(values),
    stdDev: stdDev,
    fcm: stdDev ? mean - 1.48 * stdDev : 0,
    stdDevMin: stdDev35 ? stdDev35 * 0.63 : 0,
    stdDevMax: stdDev35 ? stdDev35 * 1.37 : 100,
    targetStrength: characteristicStrength - 4
  };
}
