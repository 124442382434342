import React, { Fragment, useMemo } from 'react'
import { ReceiptWithCubeTest } from '../../containers/Sampling'
import {Cube, CubesChunckProperties, CubeTest, Family, ResourceType} from '../../types'
import ReportConsistency from './ReportConsistency'
import ReportPressureStrength from './ReportPressureStrength'
import ReportWCF from './ReportWCF'
import {EN206CubeChunks} from "./EN206CubeChunks";

export type FamilyWithReceipts = Omit<Family, 'id'> & { id: number | string, receipts: ReceiptWithCubeTest[] }

const EN206: React.FC<{ receipts: ReceiptWithCubeTest[], showConsistency: boolean, showWCF: boolean, showDeviations: boolean, startDate: Date, endDate: Date }> = ({ receipts, showConsistency, showWCF, showDeviations, startDate, endDate }) => {
  const families = useMemo(() => {
    const filtered = receipts.filter(receipt => receipt.cubeTest.cubes.filter(cube => cube.numberOfDays && cube.numberOfDays === 28 && cube.pressureStrength && cube.pressureStrength > 0).length > 0)
    return filtered.reduce((families: FamilyWithReceipts[], receipt) => {
      let recipeFamilies: Array<Omit<Family, 'id'> & { id: number | string }> = receipt.revision.recipe.families || []
      if ((!receipt.revision.recipe.families || receipt.revision.recipe.families.length === 0) && receipt.revision.recipe.strengthClass && (receipt.revision.recipe.environmentClasses || []).length > 0) {
        const maxWbf = (receipt.revision.recipe.environmentClasses || []).reduce((max, ec) => ec.maxWbf < max ? ec.maxWbf : max, 1)
        const binders = receipt.revision.recipe.ingredients.filter(({ resource }) => resource.type === ResourceType.Cement || resource.type === ResourceType.Filler).map(({ resource }) => resource).sort((a, b) => a.id - b.id)
        recipeFamilies = [{ id: `${receipt.revision.recipe.strengthClass.id}|${maxWbf}|${binders.map(({ id }) => id).join(',')}`, name: receipt.revision.recipe.strengthClass.code + ' ' + (maxWbf + '').replace('.', ',') }]
      }
      const familyIds = families.map(f => f.id + '')
      const missing = recipeFamilies.filter(family => familyIds.indexOf(family.id + '') < 0)
      const existing = recipeFamilies.filter(family => familyIds.indexOf(family.id + '') >= 0)
      existing.forEach(family => {
        const index = families.findIndex(f => f.id === family.id)
        families[index].receipts.push(receipt)
      })
      missing.forEach(family => {
        families.push({ ...family, receipts: [receipt] })
      })
      return [...families]
    }, []).sort((a, b) => (a.receipts[0].revision.recipe.strengthClass ? a.receipts[0].revision.recipe.strengthClass?.cubePressureStrength : 0) - (b.receipts[0].revision.recipe.strengthClass ? b.receipts[0].revision.recipe.strengthClass?.cubePressureStrength : 0))
  }, [receipts])

  function sortCubeTests(a: CubeTest, b: CubeTest) {
    const aDate = a.sampleDate ? new Date(a.sampleDate) : 0
    const bDate = b.sampleDate ? new Date(b.sampleDate) : 0
    return (aDate > bDate && 1) || (aDate < bDate && -1) || 0
  }

  const filtered = useMemo(() => {
    let displayChunks: Array<{ properties : CubesChunckProperties, cubes: Array<Cube & { cubeNumber: number }>}> = [];
    let characteristicStrength = 0
    for(let family of families) {
      let sorted=family.receipts.map(receipt => receipt.cubeTest).sort(sortCubeTests)

      //this SHOULD always be available but handling checks anyway
      if (family.receipts.length > 0 &&
        family.receipts[0].revision &&
        family.receipts[0].revision.recipe &&
        family.receipts[0].revision.recipe.strengthClass){
        characteristicStrength = family.receipts[0].revision.recipe.strengthClass.cubePressureStrength
      }

      let cubes = sorted.reduce((arr, cubeTest) => [...arr, ...cubeTest.cubes.filter(cube =>
        cube.numberOfDays && cube.numberOfDays === 28 && cube.pressureStrength && cube.pressureStrength > 0).map(cube =>
        ({ ...cube, cubeNumber: cubeTest.cubeNumber }))], [] as Array<Cube & { cubeNumber: number }>
      )

      displayChunks=EN206CubeChunks(cubes, startDate, endDate, characteristicStrength)
    }
    return getFilteredReceipts(receipts, displayChunks, endDate)
  }, [receipts, endDate])

  function getFilteredReceipts(receipts: ReceiptWithCubeTest[], displayChunks: Array<{ properties : CubesChunckProperties, cubes: Array<Cube & { cubeNumber: number }>}>, endDate: Date) {
    return receipts.filter(r => r.cubeTest && displayChunksHasReceipt(r, displayChunks))
  }
  function displayChunksHasReceipt(receipt: ReceiptWithCubeTest, displayChunks: Array<{ properties : CubesChunckProperties, cubes: Array<Cube & { cubeNumber: number }>}>) {
    for(let chunk of displayChunks) {
      for(let cube of chunk.cubes) {
        if(cube.receipt_test_id===receipt.cubeTest.id)
          return true
      }
    }
    return false
  }

  return <Fragment>
    {families.map(family => <ReportPressureStrength family={family} startDate={startDate} endDate={endDate} key={family.id+'-'+new Date().getTime()} />)}
    {showConsistency && <ReportConsistency receipts={filtered} startDate={startDate} showDeviations={showDeviations} />}
    {showWCF && <ReportWCF receipts={filtered} startDate={startDate} showDeviations={showDeviations} />}
  </Fragment>
}

export default EN206
